import React, { useEffect } from "react";
import "./BookAppointmentBuyGiftCard.css";

export default function BookAppointment() {
  useEffect(() => {
    if (document.body) {
      var divContainerList = document.querySelectorAll(
        'div[class="sbiz-olb-app-container"]',
      );

      if (divContainerList) {
        divContainerList.forEach((container) =>
          document.body.appendChild(container),
        );
      }
    }

    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
            //eslint-disable-next-line
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          //eslint-disable-next-line
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4),
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };

    var doNeedToUpdateViewportTag = false;
    var metaViewport;
    var metaViewportContent;
    var metaViewportList = document.querySelectorAll('meta[name="viewport"]');

    if (metaViewportList.length > 0) {
      metaViewport = metaViewportList[metaViewportList.length - 1];
      metaViewportContent = metaViewport.content;

      var list = metaViewportContent.split(",");
      var width = list.find((item) => item.includes("width"));

      if (!width || width.trim().toLowerCase() !== "width=device-width") {
        doNeedToUpdateViewportTag = true;
      }
    } else {
      var body = document.body;

      if (body && window.mobileCheck()) {
        var el = document.createElement("meta");

        el.setAttribute("name", "viewport");
        el.setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=3.0, user-scalable=1",
        );

        body.appendChild(el);
      }
    }

    var domainUrl = "https://book.salonbiz.com/";
    var appContainer = document.getElementById("sbiz-olb-app-container");
    var iframeWindow = document.getElementById("sbiz-olb-app-iframe");

    function updateContentOLBViewportTag() {
      if (!doNeedToUpdateViewportTag) return;

      metaViewport.setAttribute(
        "content",
        "width=device-width, initial-scale=1, maximum-scale=3.0, user-scalable=1",
      );
    }

    function restoreContentOLBViewportTag() {
      if (!doNeedToUpdateViewportTag) return;

      metaViewport.setAttribute("content", metaViewportContent);
    }

    function closeContainer() {
      restoreContentOLBViewportTag();

      appContainer.classList.remove("sbiz-olb-app-container--visible");
      iframeWindow.src = "about:blank";
    }

    function openContainer(urlFragment) {
      updateContentOLBViewportTag();

      iframeWindow.src = domainUrl + urlFragment;
      appContainer.classList.add("sbiz-olb-app-container--visible");
    }

    var bookingButtons = document.querySelectorAll(".sbiz-olb-booking-button");
    if (bookingButtons && bookingButtons.length > 0) {
      bookingButtons.forEach((bookingButton) => {
        bookingButton.addEventListener("click", function (evt) {
          evt.preventDefault();

          openContainer(
            "widget/#/welcome?comToken=c6468t202209141420p33783rM5dyG4fu7zH72V",
          );
        });
      });
    }

    var maxMobileAttempts = 10;
    var mobileAttempts = 0;
    var mobileDetectionIntervalId = setInterval(() => {
      mobileAttempts++;
      try {
        if (window.mobileCheck()) {
          iframeWindow.classList.remove("sbiz-olb-app-iframe--right");
          clearInterval(mobileDetectionIntervalId);
        }
      } catch (e) {}

      if (mobileAttempts >= maxMobileAttempts) {
        clearInterval(mobileDetectionIntervalId);
      }
    }, 1000);

    window.addEventListener(
      "message",
      function (message) {
        if (message.data === "closeOlbIframe") {
          localStorage.removeItem("olb-salonbiz/steps");
          closeContainer();
        }
      },
      false,
    );
  }, []);

  return (
    <div>
      <div
        id="sbiz-olb-buttons"
      >
        <a
          href="#book"
          className="sbiz-olb-booking-button"
          style={{
            color: 'inherit', // Inherit text color from parent
            textDecoration: 'none', // Remove underline
          }}
        >
            Book an Appointment
        </a>
      </div>
      <div id="sbiz-olb-app-container" class="sbiz-olb-app-container">
        <iframe id="sbiz-olb-app-iframe" class="sbiz-olb-app-iframe" title="sbiz-olb-app-iframe"></iframe>
      </div>
    </div>
  );
}
